.cursor-pointer {
  cursor: pointer !important;
}

.cursor-mover {
  cursor: move !important;
}


.d-grid {
  display: grid;
  align-items: center;
  justify-content: center;
}

.min-width-350px {
  min-width: 350px;
}

.w-90 {
  width: 90% !important;
}
.w-10 {
  width: 10% !important;
}

.max-width-200px {
  max-width: 200px;
}

.max-width-400px {
  max-width: 400px;
}

.bg-white {
  background-color: white !important;
}
