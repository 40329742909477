@import "~bootstrap/dist/css/bootstrap.css";
@import "~react-toastify/dist/ReactToastify.css";
//@import "../modules/modal/styles/pulse-modal";
//@import "../modules/stateHelpers/styles/react-state-helpers";

@import "core/animations";
@import "core/buttons";
@import "core/colours";
@import "core/dropdown";
@import "core/filters";
@import "core/form";
@import "core/icons";
@import "core/inputs";
@import "core/login";
@import "core/modal";
@import "core/navigation";
@import "core/page";
@import "core/pagination";
@import "core/plusminus";
@import "core/react-date-picker";
@import "core/stats";
@import "core/table";
@import "core/timer";
@import "core/tabs";
@import "core/toggle";
@import "core/tooltip";
@import "core/toast";
@import "core/utilities";
@import "core/variables";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
}
