@import "variables";
@import "colours";
@import "typography";

.search-bar {
  width: 100%;
  border: 1px solid transparent;
  height: auto !important;
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px rgba($vi-mid-grey, .64);
}

.select-options__control:not(.select-options__control--menu-is-open) {
  border-color: transparent !important;
}

.select-options__control[class*="select-options__control--menu-is-open"] {
  border-color: $vi-white !important;
  background-color: $vi-white !important;
  transition: $animation-duration ease;
}

.select-options__control {
  display: block;
  width: 100%;
  padding: 0 16px 0 0;
  background-color: $vi-form-input-grey !important;
  color: $vi-dark-blue !important;
  border-radius: 0 !important;
  outline: none;
  font-family: $body-font;
  font-size: 16px !important;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  min-height: 42px !important;
  height: 42px !important;
}

.select-options__control:hover {
  cursor: pointer !important;
}

.select-options__input,
.select-options__single-value {
  color: $vi-dark-blue !important;
  margin-left: 0;
  margin-right: 0;
  padding: 5px 10px;
  font-family: $body-font;
  font-weight: 400;
}

.select-options__indicator-separator {
  display: none !important;
}

.select-options__menu {
  top: 85% !important;
  background-color: $vi-white !important;
  border-radius: 5px !important;
}

.select-options__menu-notice--no-options,
.select-options__option--is-focused,
.select-options__option{
  color: $vi-dark-blue !important;
  font-size: 0.9em !important;
  font-family: $body-font;
}

.select-options__option--is-selected {
  color: $vi-white !important;
}


.select-options__option--is-focused {
  background-color: $vi-white !important;
  transition: $animation-duration ease;
  cursor: pointer;
}

.select-options__option--is-focused:hover {
  background-color: darken($vi-white, 20%) !important;
  transition: $animation-duration ease;
  cursor: pointer;
}

.select-options__option--is-selected {
  background-color: $vi-dark-golden-rod !important;
  transition: $animation-duration ease;
}

.select-options__option--is-selected:hover {
  background-color: $vi-dark-golden-rod-hover !important;
  transition: $animation-duration ease;
}

.select-options__indicator svg {
  color: $vi-dark-blue;
}

.select-options__control {
  .select-options__indicator svg {
    animation-name: rotate0;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
  }
}

.select-options__control--menu-is-open {
  .select-options__indicator svg {
    animation-name: rotate180;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
  }
}

.select-options__option {
  padding: 8px 25px !important;
}

.select-options__option:not(:first-child) {
  border-top: 1px solid $vi-dark-blue;
}

.select-options__value-container {
  padding: 2px 8px 2px 0 !important;
}
