@import "colours";
@import "variables";
@import "typography";

.stats-header-item {
    display: inline-block;
}

.bar-base {
    width: 100%;
    height: 25px;
    border-radius: 12.5px;
    background-color: $vi-form-input-grey;
    z-index: 1;
    box-shadow: 0 0 25px 1px rgba($vi-mid-grey, .64);
}

.bar-value {
    transition: all ease-in-out 1s;
    animation-delay: 0.5s;
    height: 100%;
    border-radius: 12.5px;
    z-index: 200;
}

.outer-label-text {
    color: $vi-dark-blue;
    font-family: $header-font;
    text-align: center;
}
