@import "colours";
@import "typography";
@import "variables";

.pagination {
  width: 350px;
  padding: 0;
  display: flex;
  margin: auto;
  list-style: none;
  justify-content: space-between;
  height: 25px!important;
  border-radius: 12.5px;

  color: $vi-white;
  font-family: $body-font;
  background-color: $vi-dark-blue;
}
.next-page,
.previous-page,
.other-pages,
.active-page {
  width: 30px;
  text-align: center;
}

.next-page,
.previous-page {
  color: $vi-white;
}

.other-pages {
  color: $vi-white;

  &:hover {
    cursor: pointer;
  }
}

.active-page {
  background-color: $vi-dark-golden-rod;
  color: $vi-white;
}


.other-pages:hover,
.active-page:hover {
  transition: $animation-duration ease;
  text-decoration: underline;
}

.other-pages:hover {
  background-color: $vi-dark-blue-hover;
  color: $vi-white;
}
.active-page:hover {
  cursor: pointer;
  background-color: $vi-dark-golden-rod-hover;
  color: $vi-white;
}

.next-page.disabled a,
.previous-page.disabled a {
  display: none;
}

.next-previous-icon {
  margin-top: 6px
}

.pagination-link {
  padding: .375rem .75rem;
  color: $vi-white;
  margin-left: -3px;

  &:hover {
    color: $vi-white;
  }
}

.active-pagination-link {
  color: $vi-white;
  &:hover {
    color: $vi-white;
  }
}
