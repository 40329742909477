@import "colours";
@import "variables";
@import "typography";

.nav-links-wrapper {
  display: inline-block;
  margin: 0;
  border-bottom: 3px solid $vi-dark-golden-rod;
}

.link-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding: 0 !important;
}

.nav-links-right {
  justify-content: center;
}

.system-links {
  background-color: $vi-black;
}

.nav-links {
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: $vi-white;
  font-family: $header-font;
  font-weight: 400;
  background-color: $vi-black !important;

  &:hover {
    cursor: pointer;
    color: $vi-dark-golden-rod !important;
    background-color: $vi-dark-blue-hover !important;
    transition: all ease $animation-duration;
    text-decoration: none !important;
  }
}

.current-system {
  background-color: $vi-dark-golden-rod !important;

  &:hover {
    cursor: pointer;
    color: $vi-white-hover !important;
    background: $vi-dark-golden-rod-hover;
    transition: all ease-in-out $animation-duration;
    text-decoration: none !important;
  }
}
