@import "colours";
@import "variables";
@import "typography";

.input-fields {
  margin-top: 7px;
  background-color: $vi-form-input-grey;
  width: 100%;
  border: none;
  font-family: $body-font;
  min-width: 160px !important;
  padding: 9px 10px;
  color: $vi-dark-blue;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px rgba($vi-mid-grey, .64);

  &:disabled {
    background-color: $vi-light-grey;
    color: $vi-white;
    cursor: not-allowed;
    border-left: 1px solid $vi-mid-grey;
    border-top: 1px solid $vi-mid-grey;
    border-bottom: 1px solid $vi-mid-grey;
  }

  &:invalid {
    border: 2px solid $vi-red;
  }
}
