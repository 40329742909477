@import "colours";
@import "typography";
@import "variables";

.pulse-toast-container {
  width: 500px;
}

.toast-icon {
  display: grid;
  align-items: center;
  justify-content: center;
}

.pulse-toast {
  background: $vi-dark-blue;
  color: $vi-white;
  border-radius: 0;
  min-height: 40px;
  padding: 5px 8px;
  font-family: $body-font;
  font-size: 0.8em;

  button {
    opacity: 1;

    &:hover {
      transition: $animation-duration ease-in-out opacity;
      opacity: .3;
    }
  }
  svg {
    color: $vi-white
  }
}

.pulse-toast.error-toast {
  background-color: $vi-dark-golden-rod;
}

.pulse-toast.warning-toast {
  background-color: $vi-gold;
}

.pulse-toast.success-toast {
  background-color: $vi-jelly-bean;
  color: $vi-white;

  svg {
    color: $vi-white;
  }
}

.pulse-toast-body {
  margin: 5px 0;
  padding: 0 5px;
}

@media only screen and (max-width: 600px) {
  .pulse-toast-container {
    width: 400px;
  }
}

@media only screen and (max-width: 576px) {
  .pulse-toast-container {
    width: 300px;
  }
}
