@import "colours";
@import "typography";

.timer {
  min-width: 150px !important;
  border-radius: 50px;
  padding: 10px;
  background-color: $vi-jelly-bean;
  display: inline-block;
  text-align: center;
  font-family: $header-font;
  font-weight: 700;
  color: $vi-white;
}

.timer-wrapper {
  float: right;
  position: sticky;
  top: 0;
}
