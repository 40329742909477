@import "colours";
@import "variables";
@import "typography";


.btn-base-xl,
.btn-base-lg,
.btn-base {
  color: $vi-white;
  text-align: center;
  font-family: $header-font;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 10px;
  margin: 0 2px 5px 0;
  text-decoration: none;
  display: inline-block;
  border: 1px solid transparent;
}

.btn-base-xl {
  height: 42px;
  font-size: 1rem;
}

.btn-base-lg {
  height: auto;
  font-size: 1rem;
}

.btn-base {
  height: 25px;
  font-size: 12px;
}

.btn-base-xl.rounded-corner {
  border-radius: 5px;
}

.btn-base-lg.rounded-corner {
  border-radius: 5px;
}
.btn-base.rounded-corner {
  border-radius: 5px;
}

.btn-base-xl.scaled-font,
.btn-base-lg.scaled-font,
.btn-base.scaled-font {
  font-size: clamp(0.6em, unquote("0.3vw + 0.45rem"), 0.8em) !important;
}

.btn-base.green-btn,
.btn-base.light-blue-btn,
.btn-base.orange-btn,
.btn-base.red-btn,
.btn-base.yellow-btn,
.btn-base.dark-blue-btn,
.btn-base-lg.green-btn-lg,
.btn-base-lg.light-blue-btn-lg,
.btn-base-lg.orange-btn-lg,
.btn-base-lg.red-btn-lg,
.btn-base-lg.yellow-btn-lg,
.btn-base-lg.dark-blue-btn-lg,
.btn-base-xl.green-btn-xl,
.btn-base-xl.light-blue-btn-xl,
.btn-base-xl.orange-btn-xl,
.btn-base-xl.red-btn-xl,
.btn-base-xl.yellow-btn-xl,
.btn-base-xl.dark-blue-btn-xl {
  width: auto;
  &:hover {
    cursor: pointer;
    transition: all $animation-duration ease;
    -webkit-transition: all $animation-duration ease;
  }
  &:disabled {
    cursor: default;
    transition: all $animation-duration ease;
    -webkit-transition: all $animation-duration ease;
  }
}

.btn-base.light-blue-btn,
.btn-base-lg.light-blue-btn-lg,
.btn-base-xl.light-blue-btn-xl {
  background-color: $vi-jelly-bean;
  border: 1px solid $vi-jelly-bean;

  &:hover {
    background-color: $vi-jelly-bean-hover;
    border: 1px solid $vi-jelly-bean-hover;
  }

  &:disabled {
    background-color: $vi-light-grey;
    border: 1px solid $vi-light-grey;
  }
}

.btn-base.orange-btn,
.btn-base-lg.orange-btn-lg,
.btn-base-xl.orange-btn-xl {
  background-color: $vi-dark-golden-rod;
  border: 1px solid $vi-dark-golden-rod;

  &:hover {
    background-color: $vi-dark-golden-rod-hover;
    border: 1px solid $vi-dark-golden-rod-hover;
  }

  &:disabled {
    background-color: $vi-light-grey;
    border: 1px solid $vi-light-grey;
  }
}

.btn-base.dark-blue-btn,
.btn-base-lg.dark-blue-btn-lg,
.btn-base-xl.dark-blue-btn-xl {
  background-color: $vi-dark-blue;
  border: 1px solid $vi-dark-blue;

  &:hover {
    background-color: $vi-dark-blue-hover;
    border: 1px solid $vi-dark-blue-hover;
  }

  &:disabled {
    background-color: $vi-light-grey;
    border: 1px solid $vi-light-grey;
  }
}
