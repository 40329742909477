@import "colours";
@import "typography";
@import "variables";

.filters-table {
  width: 100%;
  border-collapse: collapse;
}

.filter-heading {
  color: $vi-dark-blue;
  font-family: $header-font;
  font-weight: 200 !important;
}

.w-33 {
  width: 33.333333%;
  min-width: 265px !important;
}

.w-half {
  width: 50%;
}

.filter-border-bottom {
  border-bottom: 1px solid $vi-dark-blue;
}

.filter-container {
  overflow: hidden;
}

.filter-container.open {
  max-height: 500px;
  transition: all $animation-duration ease-in-out;
  border-bottom: 1px solid $vi-dark-blue;
}

.filter-container.closed {
  max-height: 0;
  transition: all $animation-duration ease-in-out;
  border-bottom: 1px solid transparent;
}

.filters-lg {
  display: table-row-group;
}
.filters-md {
  display: none;
}
.filters-sm {
  display: none;
}

@media only screen and (max-width: 850px) {
  .filters-lg {
    display: none;
  }
  .filters-md {
    display: table-row-group;
  }
  .filters-sm {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .filters-lg {
    display: none;
  }
  .filters-md {
    display: none;
  }
  .filters-sm {
    display: table-row-group;
  }
}
