@import "colours";
@import "typography";
@import "variables";

.layout-wrapper {
  background-color: $vi-lighter-grey;
  position: relative;
  min-height: 94vh;

  animation-name: fadeInOpacity;
  animation-duration: $animation-duration;
}

.vi-logo {
  width: 160px;
}

.header,
.footer {
  background-color: $vi-black;
}

.footer-text {
  color: $vi-white;
  font-family: $header-font;
  font-size: 0.8em;
}
