@import "colours";

.vi-form-wrapper {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 25px 1px rgba($vi-mid-grey, .34);
  border: 1px solid $vi-white-hover;
}

.vi-form-heading {
  color: $vi-dark-golden-rod;
  padding: 10px 0;
}

.vi-form-row-splitter {
  border-bottom: 1px solid $vi-dark-golden-rod;
}

.mc-form-row {
  margin: 15px !important;
}
