@import "colours";
@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;1,200&display=swap');

$header-font: 'Titillium Web', sans-serif;
$body-font: "Roboto", "Helvetica", "Arial", sans-serif;

li, a {
  text-decoration: none !important;
}

.body-font,
p, a {
  font-family: $body-font;
}

h1, h2, h3, h4, h5, h6,
.header-font {
  font-family: $header-font;
}

.point-eight-em {
  font-size: 0.8em !important;
}
.one-em {
  font-size: 1em !important;
}
.one-point-zero-five-em {
  font-size: 1.05em !important;
}

.white-text {
  color: $vi-white;
}

.dark-blue-text {
  color: $vi-dark-blue !important;
}

.orange-text {
  color: $vi-dark-golden-rod;
}

.air-force-blue-text {
  color: $vi-air-force-blue;
}

.red-text {
  color: $vi-red;
}


.orange-hyperlink-text {
  color: $vi-dark-golden-rod;

  &:hover {
    color: $vi-dark-golden-rod-hover;
    transition: all $animation-duration ease;
    text-decoration: underline;
  }
}

.overflow-wrap {
  overflow-wrap: break-word !important;
  word-break: keep-all !important;
}

