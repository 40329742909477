$vi-white: #eeeeee;
$vi-light-grey: #61646b;
$vi-mid-grey: #48515a;
$vi-black: #171717;
$vi-dark-blue: #112a39;
$vi-gold: #868461;
$vi-jelly-bean: #358192;
$vi-army-green: #505421;
$vi-dark-golden-rod: #bc8403;
$vi-air-force-blue: #5683ad;
$vi-red: #b93232;

$vi-dark-blue-hover: darken($vi-dark-blue, 10%);
$vi-red-hover: darken($vi-red, 10%);
$vi-white-hover: darken($vi-white, 10%);
$vi-jelly-bean-hover: darken($vi-jelly-bean, 10%);
$vi-dark-golden-rod-hover: darken($vi-dark-golden-rod, 10%);

$vi-lighter-grey: lighten($vi-light-grey, 48%);

$vi-form-input-grey: lighten($vi-light-grey, 44%);

$vi-dark-blue-transparent: #112a39aa;
