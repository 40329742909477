@import "colours";
@import "typography";
@import "variables";

.tab {
  background-color: $vi-white;
  color: $vi-dark-blue;
  border: none;
  cursor: pointer;
  font-size: 0.85em;
  min-width: 110px;
  height: auto;
  display: grid;
  justify-content: center;
  align-items: center;

  border-bottom: 3px solid transparent;

  &:hover {
    background-color: $vi-white-hover;
    border-bottom: 3px solid $vi-dark-golden-rod;
    transition: all ease-in-out $animation-duration;
  }
}
.tab-content-outer {
  padding: 15px 5px;
  background-color: $vi-white;
  height: 550px;
}

.tab p {
  padding: 15px 10px;
  font-size: 0.9em;
  font-weight: 700;
}

.tab.active {
  background-color: $vi-jelly-bean;
  color: $vi-white;
  border-bottom: 2px solid $vi-jelly-bean;

  &:hover {
    background-color: $vi-jelly-bean-hover;
    border-bottom: 3px solid $vi-dark-golden-rod;
    transition: all ease-in-out $animation-duration;
  }
}
