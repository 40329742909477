@import "colours";
@import "typography";

.tooltip-override-xs,
.tooltip-override-sm,
.tooltip-override-md,
.tooltip-override-lg {
  text-align: center !important;
  overflow: visible;
  font-family: $body-font;
  background: $vi-dark-blue !important;
  border-radius: 0 !important;
}

.tooltip-override-xs {
  width: 100px;
}
.tooltip-override-sm {
  width: 150px !important;
}
.tooltip-override-md {
  width: 200px;
}
.tooltip-override-lg {
  width: 250px;
}

.__react_component_tooltip {
  transition: none !important;
}
