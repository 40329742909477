@import "colours";
@import "typography";

.vi-table-wrapper {
  box-shadow: 0 0 25px 1px rgba($vi-mid-grey, .64);
  border-radius: 5px;
}

.vi-table {
  width: 100%;
  border-collapse: collapse;
}

.vi-table-header {
  background: $vi-dark-golden-rod;
  border-bottom: 1px solid $vi-dark-golden-rod;
}

tr.vi-table-header:first-child th:first-child { border-top-left-radius: 5px; }
tr.vi-table-header:first-child th:last-child { border-top-right-radius: 5px; }

tr.vi-table-row:last-child td:first-child { border-bottom-left-radius: 5px; }
tr.vi-table-row:last-child td:last-child { border-bottom-right-radius: 5px; }

.vi-table-header-item {
  width: 20%;
  min-width: 150px !important;
  font-family: $header-font;
  color: $vi-white;
  font-size: 1em;
}

.vi-table-row {
  background-color: $vi-white;
  &:nth-child(odd) {
    background-color: $vi-lighter-grey;
  }
}
.vi-table-row-item {
  width: 20%;
  font-family: $body-font;
  font-size: 0.9em;
}

.table-overflow-x {
  overflow-x: auto;
}

.table-overflow-y {
  overflow-y: visible !important;
}

.table-width-wrapper {
  min-width: 1000px;
}
