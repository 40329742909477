@import "colours";
@import "typography";
@import "variables";

.modal-background-window {
  background: $vi-white-hover;
}
.modal-text-colour {
  color: $vi-dark-blue;
  font-family: $body-font;
}

.modal {
  .header-text {
    font-family: $header-font;
  }
}
@media screen and (max-width: 400px){
  .modal {
    .wrapper-xs {
      width: 300px;
      transition: all ease $animation-duration;
    }
  }
}

$animation-duration: 300ms;
$light-theme-font: #495057;
$light-theme-background: #eeeeee;

.modal-background-window {
  background: $light-theme-background;
}
.modal-text-colour {
  color: $light-theme-font
}

.modal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;


  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 450;
  }

  .wrapper-xl,
  .wrapper-md,
  .wrapper-sm,
  .wrapper-xs {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: 0;
  }

  .wrapper-xs {
    z-index: 800;
    width: 400px;
  }

  .wrapper-sm {
    z-index: 800;
    width: 800px;
  }

  .wrapper-md {
    z-index: 700;
    width: 1200px;
  }

  .wrapper-lg {
    z-index: 700;
    width: 1450px;
  }

  .wrapper-xl {
    z-index: 700;
    max-width: 1700px;
    width: auto;
    min-width: 500px !important;
  }

  .styled-modal-md {
    height: 720px;
  }

  .styled-modal-lg,
  .styled-modal-xl {
    height: 850px;
    overflow-y: auto;
  }

  .styled-modal-sm {
    min-height: 550px;
    max-height: 850px;
    overflow-y: auto;
  }

  .styled-modal-sm,
  .styled-modal-xs {
    height: auto;
  }

  .styled-modal-xs {
    max-height: 600px !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .styled-modal-xl,
  .styled-modal-lg,
  .styled-modal-md,
  .styled-modal-sm,
  .styled-modal-xs {
    z-index: 100;
    position: relative;
    margin: auto;
    padding: 10px 20px;
  }

  .header-text {
    font-weight: bold;
  }

  .close-button {
    width: 20px !important;
    height: 20px !important;
  }

  .close-button:hover {
    cursor: pointer !important;
  }

  .styled-modal-xl > .content {
    padding: 10px 5px;
    height: calc(100% - 105px) !important;
  }

  .content {
    padding: 10px 5px;
    height: calc(100% - 40px);
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .button-wrapper {
    display: flex;
  }

  .button-text {
    padding-right: 20px;
  }
}

@media screen and (min-height: 1009px){
  .modal {
    .styled-modal-xl {
      height: 850px;
      transition: all ease $animation-duration;
    }
  }
}
@media screen and (max-height: 1008px){
  .modal {
    .styled-modal-xl {
      height: 650px;
      transition: all ease $animation-duration;
    }
    .styled-modal-sm {
      max-height: 850px;
      transition: all ease $animation-duration;
    }
  }
}
@media screen and (max-height: 850px){
  .modal {
    .styled-modal-sm {
      max-height: 650px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-height: 730px){
  .modal {
    .styled-modal-md {
      height: 650px;
      transition: all ease $animation-duration;
    }
  }

  .drug-store-stock-container {
    height: 400px !important;
    transition: all ease $animation-duration;
  }
}

@media screen and (max-height: 660px){
  .modal {
    .styled-modal-xl {
      height: 450px;
      transition: all ease $animation-duration;
    }
    .styled-modal-sm {
      max-height: 450px;
      min-height: 250px;
      transition: all ease $animation-duration;
    }

    .styled-modal-md {
      height: 450px;
      transition: all ease $animation-duration;
    }
  }

  .drug-store-stock-container {
    height: 200px !important;
    transition: all ease $animation-duration;
  }
}

@media screen and (max-height: 460px){
  .modal {
    .styled-modal-xl {
      height: 350px;
      transition: all ease $animation-duration;
    }

    .styled-modal-sm {
      max-height: 350px;
      min-height: 250px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-width: 1250px){
  .modal {
    .wrapper-md {
      width: 850px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (min-width: 900px){
  .modal {
    .wrapper-xl {
      width: 850px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-width: 899px){
  .modal {
    .wrapper-xl {
      width: 700px;
      transition: all ease $animation-duration;
    }

    .wrapper-md {
      width: 750px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-width: 830px){
  .modal {
    .wrapper-sm {
      width: 500px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-width: 768px){
  .modal {
    .wrapper-md,
    .wrapper-xl {
      width: 500px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-width: 500px){
  .modal {
    .wrapper-md {
      width: 400px;
      transition: all ease $animation-duration;
    }
    .wrapper-sm {
      width: 400px;
      transition: all ease $animation-duration;
    }
  }
}

@media screen and (max-width: 400px){
  .modal {
    .wrapper-md {
      width: 300px;
      transition: all ease $animation-duration;
    }
    .wrapper-sm {
      width: 300px;
      transition: all ease $animation-duration;
    }
  }
}
