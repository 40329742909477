@import "colours";

.wrapper{
  height: 42px;
  display: flex;
  max-width: 100px;
  align-items: center;
  justify-content: center;
  background-color: $vi-form-input-grey;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px rgba($vi-mid-grey, .64);
}
.wrapper span {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  transition: background-color ease-in-out 300ms;
}
.wrapper span.num{
  font-size: 1rem;
  border-right: 2px solid rgba(0,0,0,0.2);
  border-left: 2px solid rgba(0,0,0,0.2);
  pointer-events: none;
}

.wrapper span.minus,
.wrapper span.plus {
  padding: 7px 0;
  &:hover {
    background-color: darken($vi-form-input-grey, 20%)
  }
}

.wrapper span.minus {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.wrapper span.plus {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
